import React, { useCallback, useEffect, useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import { apiPost } from '../../../utility/api'
import Text, { withSmashWidows } from '../../shared/Text'
import Button from '../../shared/Button'
import styles from '../../../utility/styles'
import {
	ArrowRight,
	GiftIcon,
	SmallFoundersBlack,
	SmallProBlack,
} from '../../../utility/icons'
import confetti from '../../../utility/lottieFiles/Confetti_Gold.json'
import Input from '../../shared/Input'

const STANDARD_ERROR = 'An error has occured, please try again or contact support@tezlabapp.com if this problem persists'

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 25px 38px 25px 32px;
	background-color: ${({ expanded }) =>
		expanded ? styles.colors.background : styles.colors.darkGray};
	border: 2px solid ${styles.colors.darkGray};
	margin-bottom: 48px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
`

const Row = styled.button`
	display: flex;
	flex: 1;
	align-items: center;
	background-color: transparent;
	border: none;
`

const TextWrapper = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
`

const StyledArrow = styled.div`
	animation: ${({ expanded }) =>
			styles.animations.rotate(
				expanded ? '0deg' : '-90deg',
				expanded ? '-90deg' : '0deg'
			)}
		0.5s ease-in-out forwards;
`

const InputWrapper = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;
	margin-top: ${({ expanded }) => (expanded ? '24px' : '0')};
	transition: margin 0.5s ease-in-out;
	position: relative;
	height: 0;
	opacity: 0;
	animation: ${({ expanded }) =>
			expanded === true
				? styles.animations.show('0', '0', '54px', '1')
				: expanded === false
				? styles.animations.hide('54px', '1')
				: 'none'}
		0.5s ease-in-out forwards;
	@media only screen and ${styles.breakpoints.mobileL} {
		flex-wrap: wrap;
		animation: ${({ expanded }) =>
				expanded === true
					? styles.animations.show('0', '0', '174px', '1')
					: expanded === false
					? styles.animations.hide('174px', '1')
					: 'none'}
			0.5s ease-in-out forwards;
		justify-content: center;
	}
`

const CheckOneWrapper = styled.div`
	position: absolute;
	left: 30%;
	display: ${({ visible }) => (visible ? 'block' : 'none')};
`

const CheckTwoWrapper = styled.div`
	position: absolute;
	left: 67%;
	display: ${({ visible }) => (visible ? 'block' : 'none')};
`

// gift section

const GiftWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
	margin: ${({ visible }) => (visible ? '24px' : '0')} 0 0 0;
	height: 0;
	opacity: 0;
	overflow: hidden;
	animation: ${({ visible }) =>
			visible === true
				? styles.animations.show('0', '0', '224px', '1')
				: visible === false
				? styles.animations.hide('224px', '1', '0', '0')
				: 'none'}
		0.5s ease-in-out forwards;
`

const LottieWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 150px;
	width: 100%;
	margin-bottom: 24px;
`

const ButtonThing = styled.div`
	background-color: ${styles.colors.founders};
	border-radius: 4px;
	padding: 8px 24px;
	display: flex;
	align-items: center;
	z-index: 100;
`

const CenterAbsolute = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`

const FakeRedemptionButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 24px;
	border-radius: 2px;
	background-color: ${styles.colors.green};
`

const icons = {
	founders: <SmallFoundersBlack />,
	pro: <SmallProBlack />,
}

const SmallIcon = ({ type }) => icons[type] ?? <div />

const ButtonText = withSmashWidows(Text.Body)

const LOADING = {
	none: '',
	check: 'check',
	redeem: 'redeem',
}

/**
 *
 * @param {{gift_redemption_lookup:string, redemption_code_prefill:string}} props
 * @returns
 */
export default function GiftRedemptionPanel({
	gift_redemption_lookup,
	redemption_code_prefill,
}) {
	const [expanded, setExpanded] = useState(null)
	const [visible, setVisible] = useState(null)
	const [code, setCode] = useState(redemption_code_prefill ?? '')
	const [pin, setPin] = useState('')
	const [data, setData] = useState(null)
	const [redemptionData, setRedemptionData] = useState(null)
	const [loading, setLoading] = useState(LOADING.none)
	const [error, setError] = useState('')

	const onChangeCode = ({ target }) => setCode(target?.value)
	const onChangePin = ({ target }) => setPin(target?.value)
	const toggleExpanded = () =>
		setExpanded(bool => (bool === null ? true : !bool))
	const onSubmit = e => e?.preventDefault()

	const onSubmitVoucher = useCallback(async () => {
		try {
			setLoading(LOADING.check)
			setError('')
			const { data, success, message } = await apiPost(
				gift_redemption_lookup,
				{
					code,
					pin,
				}
			)
			if (message) throw message
			if (!success || !data) throw STANDARD_ERROR
			if (!data.eligible_for_redemption) setError('This gift cannot be redeemed due to a conflict with your current membership level.\nPlease contact support@tezlabapp.com for further assistance.')
			setData(data)
			setVisible(true)
			setLoading(LOADING.none)
		} catch (error) {
			setLoading(LOADING.none)
			setError(
				typeof error === 'string' ? error : STANDARD_ERROR
			)
		}
	}, [gift_redemption_lookup, code, pin])

	const onRedeem = useCallback(async () => {
		if (!data) return
		try {
			setLoading(LOADING.redeem)
			setError('')
			const {
				data: redemptionData,
				message,
				success,
			} = await apiPost(
				data.redemption_action,
				{
					code: data.redemption_code,
					pin,
					redemption_key: data.redemption_key,
				},
				{
					headers: {
						Authorization: `Bearer ${data.redemption_token}`,
					},
				}
			)
			if (message) throw message
			if (!success || !redemptionData) throw STANDARD_ERROR
			setLoading(LOADING.none)
			setRedemptionData(redemptionData)
			setTimeout(() => window.location.reload(), 750)
		} catch (error) {
			setLoading(LOADING.none)
			setError(
				typeof error === 'string' ? error : STANDARD_ERROR
			)
		}
	}, [data])

	useEffect(() => {
		if (redemption_code_prefill) {
			setExpanded(true)
			setCode(redemption_code_prefill)
		}
	}, [redemption_code_prefill])

	useEffect(() => {
		if (data) {
			// if pin and/or code are updated, reset data
			setData(null)
			setVisible(false)
		}
	}, [code, pin])

	if (!gift_redemption_lookup && !redemption_code_prefill) return false

	return (
		<Wrapper {...{ expanded }}>
			<Row onClick={toggleExpanded} disabled={Boolean(data)}>
				<GiftIcon />
				<TextWrapper>
					<Text.Heading
						type='h2'
						mobileL='h3'
						mobileS='h5'
						allowWidows
						variant='bold'
						color={styles.colors.whiteGray}
						style={{
							textAlign: 'left',
							marginLeft: '12px',
						}}
					>
						Redeem a Gift
					</Text.Heading>
				</TextWrapper>
				<StyledArrow {...{ expanded }}>
					<ArrowRight fill={styles.colors.whiteGray} />
				</StyledArrow>
			</Row>
			<Form {...{ onSubmit }} className='d-flex justify-content-center'>
				<InputWrapper {...{ expanded }}>
					<Input
						value={code}
						onChange={onChangeCode}
						label='Redemption Code'
						style={{ margin: '0 2px' }}
					/>
					<Input
						value={pin}
						onChange={onChangePin}
						label='Secret PIN'
						style={{ margin: '0 2px' }}
					/>
					<Button
						disabled={!code || !pin || !!data}
						success={!!data}
						loading={loading === LOADING.check}
						onClick={onSubmitVoucher}
						style={{ padding: '11px 24px', margin: '0 2px' }}
					>
						<ButtonText
							variant='bold'
							color={
								!!data
									? styles.colors.background
									: styles.colors.white
							}
						>
							Check Gift
						</ButtonText>
					</Button>
					<CheckOneWrapper>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<circle cx='12' cy='12' r='9' fill='white' />
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM8.29289 13.7071C7.90237 13.3166 7.90237 12.6834 8.29289 12.2929C8.68342 11.9024 9.31658 11.9024 9.70711 12.2929L11 13.5858L15.2929 9.29289C15.6834 8.90237 16.3166 8.90237 16.7071 9.29289C17.0976 9.68342 17.0976 10.3166 16.7071 10.7071L11.7071 15.7071C11.3166 16.0976 10.6834 16.0976 10.2929 15.7071L8.29289 13.7071Z'
								fill='#8BC16A'
							/>
						</svg>
					</CheckOneWrapper>
					<CheckTwoWrapper>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<circle cx='12' cy='12' r='9' fill='white' />
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM8.29289 13.7071C7.90237 13.3166 7.90237 12.6834 8.29289 12.2929C8.68342 11.9024 9.31658 11.9024 9.70711 12.2929L11 13.5858L15.2929 9.29289C15.6834 8.90237 16.3166 8.90237 16.7071 9.29289C17.0976 9.68342 17.0976 10.3166 16.7071 10.7071L11.7071 15.7071C11.3166 16.0976 10.6834 16.0976 10.2929 15.7071L8.29289 13.7071Z'
								fill='#8BC16A'
							/>
						</svg>
					</CheckTwoWrapper>
				</InputWrapper>
			</Form>
			<GiftWrapper {...{ visible }}>
				<LottieWrapper>
					<CenterAbsolute>
						<Player
							src={confetti}
							style={{
								width: '100%',
								height: '100%',
							}}
							autoplay
							loop
						/>
					</CenterAbsolute>
					<ButtonThing>
						<SmallIcon type={data?.icon} />
						<Text.Caption
							variant='bold'
							color={styles.colors.background}
							style={{ marginLeft: '16px' }}
						>
							{data?.title}
						</Text.Caption>
					</ButtonThing>
				</LottieWrapper>
				{data?.eligible_for_redemption && (
					data?.redeemed ? (
						<FakeRedemptionButton>
							<Text.Body
								variant='bold'
								color={styles.colors.background}
							>
								This gift has been redeemed!
							</Text.Body>
						</FakeRedemptionButton>
					) : (
						<Button
							disabled={
								loading ||
								!data ||
								!!redemptionData ||
								!data.eligible_for_redemption
							}
							color={
								!!redemptionData
									? styles.colors.background
									: styles.colors.white
							}
							success={!!redemptionData}
							onClick={onRedeem}
							loading={LOADING.redeem === loading}
							style={{
								padding: '12px 24px',
								fontSize: '16px',
								lineHeight: '24px',
							}}
							variant={
								loading
									? 'secondary_alt'
									: !!redemptionData
									? 'success'
									: 'primary'
							}
						>
							{!!redemptionData
								? 'Gift Redeemed'
								: !data?.eligible_for_redemption
								? "Unable to Redeem"
								: 'Redeem Now'}
						</Button>
					)
				)}
				
			</GiftWrapper>
			<Text.Body
				style={{ textAlign: 'center', marginTop: error ? '12px' : '0' }}
				color={styles.colors.alert}
			>
				{error}
			</Text.Body>
		</Wrapper>
	)
}
