import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Text from "./Text";
import { Check, NoCheck } from "../../utility/icons";
import styles from "../../utility/styles";
import Button from './Button';

const Divider = styled.hr`
    color: ${({ color }) => color};
    height: ${({ height }) => height}px;
    margin: 0 41px 16px 41px;
    @media only screen and ${styles.breakpoints.mobileL} {
        margin: 0 9px 16px 9px;
    }
`;

const Card = styled.div`
    display: flex;
    flex: 1;
    background: ${styles.colors.darkGray};
    padding: 32px 40px;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    margin: 0 8px;
    @media only screen and ${styles.breakpoints.mobileL} {
        padding: 16px 20px;
        margin: 8px 0;
    }
`;

const StyledRow = styled(Row)`
    margin: 0 41px 16px 41px;
    @media only screen and ${styles.breakpoints.mobileL} {
        margin: 0 9px 16px 9px;
    }
`;

const ButtonRow = styled.div`
    display: flex;
    @media only screen and ${styles.breakpoints.mobileL} {
        flex-direction: column;
        align-items: center;
        & > button {
            margin: 8px 0 !important;
        }
    }
`;

const Heading = () => {
    return (
        <StyledRow className='g-0'>
            <Col xs={8} className='d-flex align-items-center justify-content-center' />
            <Col xs={2} className='d-flex align-items-start justify-content-center'>
                <Text.Heading variant='bold' type='h5' color={styles.colors.dimHighlight}>Free</Text.Heading>
            </Col>
            <Col xs={2} className='d-flex align-items-center justify-content-center'>
                <Text.Heading variant='bold' type='h5' style={{ color: styles.colors.founders }}>Pro</Text.Heading>
            </Col>
        </StyledRow>
    )
}

const Footer = ({ onClick, monthly, annual, key_features = [], disclaimer, ...props }) => {
    const freeFeatures = key_features.filter(({ is_free }) => is_free === true);
    const proFeatures = key_features.filter(({ is_free }) => is_free === false);
    const onClickInternal = e => {
        e.preventDefault();
        if (typeof onClick === 'function') {
            onClick();
        } else {
            window.location = '/subscribe#input';
        }
    }
    return (
        <StyledRow className='g-0' {...props}>
            <Col sm={12} md={6} className='d-flex justify-content-center'>
                <Card>
                    <div>
                        <Text.Heading type='h2' variant='bold' color={styles.colors.dimHighlight}>Basic</Text.Heading>
                        <Text.Body color={styles.colors.dimHighlight} style={{ marginBottom: '16px' }}>Free</Text.Body>
                        <Text.Body color={styles.colors.lightGray}>Key Features</Text.Body>
                        {freeFeatures.map(({ text }, index) => (
                            <Text.Body key={text}>{text}</Text.Body>
                        ))}
                    </div>
                    <div>
                        <Divider color={styles.colors.mediumGray} height={1} style={{ margin: '16px 0' }} />
                        <Text.Body color={styles.colors.lightGray} style={{ textAlign: 'center' }}>Free</Text.Body>
                    </div>
                </Card>
            </Col>
            <Col sm={12} md={6} className='d-flex justify-content-center'>
                <Card style={{ backgroundColor: styles.colors.founders }}>
                    <div>
                        <Text.Heading type='h2' variant='bold' color={styles.colors.black}>TezLab Pro</Text.Heading>
                        <Text.Body color={styles.colors.black} style={{ marginBottom: '16px' }}>{monthly} Monthly or {annual} Annually</Text.Body>
                        <Text.Body color={styles.colors.black}>Key Features</Text.Body>
                        {proFeatures.map(({ text }, index) => (
                            <Text.Body key={text} color={styles.colors.black}>{text}</Text.Body>
                        ))}
                    </div>
                    <div>
                        <Divider color={styles.colors.black} height={1} style={{ margin: '16px 0' }} />
                        <ButtonRow>
                            <Button onClick={onClickInternal} pill style={{ flex: 1, margin: '0 4px 0 48px', backgroundColor: styles.colors.transparent, border: `1px solid ${styles.colors.black}`, color: styles.colors.black }}>{monthly}/Month</Button>
                            <Button onClick={onClickInternal} pill style={{ flex: 1, margin: '0 48px 0 4px', backgroundColor: styles.colors.black, border: `1px solid ${styles.colors.black}`, color: styles.colors.founders }}>{annual}/Year</Button>
                        </ButtonRow>
                        <Text.Caption color={styles.colors.black} style={{ marginTop: '8px' }}>{disclaimer}</Text.Caption>
                    </div>
                </Card>
            </Col>
        </StyledRow>
    )
}

/**
 * 
 * @param {{text:string, freeText:string, size:string, type:string}} props
 * @returns 
 */
const Body = ({ text, freeText, isFree, size = 'Heading', type = 'h6' }) => {
    return (
        <>
            <Divider color={styles.colors.darkGray} height={1} />
            <StyledRow className='g-0'>
                <Col xs={8} className='d-flex align-items-center'>
                    {React.createElement(Text[size], { type, color: styles.colors.lightGray }, text)}
                </Col>
                <Col xs={2} className='d-flex align-items-center justify-content-center'>
                    {freeText ? (
                        <Text.Tiny color={styles.colors.mediumGray}>{freeText}</Text.Tiny>
                    ) : (
                        <NoCheck />
                    )}
                </Col>
                <Col xs={2} className='d-flex align-items-center justify-content-center'>
                    <Check backgroundFill={styles.colors.background} fill={styles.colors.founders} />
                </Col>
            </StyledRow>
        </>
    )
}

export default {
    Heading,
    Footer,
    Body
}