import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Switch from 'react-bootstrap/Switch';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';
import Text from '../../shared/Text';
import styles from '../../../utility/styles';
import { FoundersIcon, ProIconGold } from '../../../utility/icons';
import Button from '../../shared/Button';

const StyledSwitch = styled(Switch)`
    display: flex;
    & .form-check-input {
        cursor: pointer;
        height: 32px;
        width: 48px;
        background-color: ${styles.colors.lightGray};
        border-color: ${styles.colors.lightGray};
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='%23fff'/%3e%3c/svg%3e");
        &:checked {
            background-color: ${styles.colors.success};
            border-color: ${styles.colors.success};
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='%23fff'/%3e%3c/svg%3e");
        }
		@media only screen and (max-width: 375px) {
			height: 24px;
			width: 36px;
		}
    }
`

const Title = styled(Offcanvas.Title)`
    font-family: var(--Font-Family, "Nunito Sans");
    font-size: var(--Para, 16px);
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 150% */
`

const Header = styled(Offcanvas.Header)`
    padding: 16px 24px;
`;

const Divider = styled.hr`
    color: ${styles.colors.darkGray};
    margin: 16px 0;
`

const StyledOffcanvas = styled(Offcanvas)`
    background-color: ${styles.colors.background};
    width: 50%;
    max-width: 600px;
    .offcanvas-header .btn-close {
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.27566 4.27566C3.90811 4.64322 3.90811 5.23914 4.27566 5.60669L10.1984 11.5294L4.27566 17.4521C3.90811 17.8197 3.90811 18.4156 4.27566 18.7832C4.64322 19.1507 5.23914 19.1507 5.60669 18.7832L11.5294 12.8604L17.5859 18.9169C17.9535 19.2845 18.5494 19.2845 18.9169 18.9169C19.2845 18.5494 19.2845 17.9535 18.9169 17.5859L12.8604 11.5294L18.7832 5.60669C19.1507 5.23914 19.1507 4.64322 18.7832 4.27566C18.4156 3.90811 17.8197 3.90811 17.4521 4.27566L11.5294 10.1984L5.60669 4.27566C5.23914 3.90811 4.64322 3.90811 4.27566 4.27566Z' fill='white'/%3e%3c/svg%3e") center / 1em auto no-repeat;
    }
    .offcanvas-header {
        background-color: ${styles.colors.background};
        color: ${styles.colors.white};
        border-bottom: 1px solid ${styles.colors.darkGray};
        font-family: var(--Font-Family, "Nunito Sans");
        font-size: var(--Para, 16px);
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 150% */
    }
    .offcanvas-body {
        padding: 32px 32px calc(80px + 32px) 32px;
        height: calc(100% - 56px);
        position: relative;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledCol = styled.div`
    display: flex;
    flex-direction: column;
`;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 32px;
`

/**
 * 
 * @param {{
 *   subscription: {
 *     type: 'Free'|'Pro'|'Founders',
 *     renewalType: 'Monthly'|'Annual',
 *     renewalMessage: string,
 *     isAutoRenew: boolean,
 *     showManageButton: boolean,
 *   },
 *   show: boolean,
 *   onHide: () => void
 *   vehicleConnection: {
 *     addOnQty: number,
 *   },
 *   connectAdjust: {
 *     current_connections: number,
 *     min_connections: number,
 *     max_connections: number,
 *     stripe_is_vehicle_connections_only: boolean,
 *     stripe_has_vehicle_connections_item: boolean,
 *   },
 *   onManagePress: () => void,
 *   toggleAutoRenew: () => void,
 *   isTogglingAutoRenew: boolean,
 *   renewalError: string,
 * }} props
 * @returns 
 */
export default function ManageSubscriptionPanel({
    show,
    onHide,
    subscription,
    vehicleConnection,
    onManagePress,
    toggleAutoRenew,
    isTogglingAutoRenew,
    renewalError
}) {


    return (
        <StyledOffcanvas {...{ show, onHide }} placement='end'>
            <Header closeButton>
                <Title>Manage Subscription</Title>
            </Header>
            <Offcanvas.Body>
                {!!renewalError && (
                    <Text.Body variant='bold' color={styles.colors.error} style={{ marginBottom: '16px' }}>{renewalError}</Text.Body>
                )}
                <StyledRow>
                    <StyledCol>
                        <Text.Caption color={styles.colors.founders}>TezLab {subscription?.type == "Founders" ? "Pro (Founders Series)" : subscription?.type}{subscription?.renewalType ? ` / ${subscription?.renewalType}` : ''}</Text.Caption>
                        {!!subscription?.renewalMessage && <Text.Caption color={styles.colors.lightGray}>{subscription?.renewalMessage}</Text.Caption>}
                    </StyledCol>
                    {subscription?.type === 'Pro' && (
                        <ProIconGold />
                    )}
                    {subscription?.type === 'Founders' && (
                        <FoundersIcon />
                    )}
                </StyledRow>
                {(subscription?.subscriptionPlatform == "TezLab" || subscription?.type == "Founders") && (
                    <>
                        <Divider />
                        <StyledRow style={{ marginBottom: '16px' }}>
                            <Text.Body variant='bold'>Auto-Renew{subscription?.type === 'Founders' ? ' Vehicle Connections' : ''}</Text.Body>
                            {isTogglingAutoRenew ? (
                                <SpinnerWrapper>
                                    <Spinner animation='border' variant='light' />
                                </SpinnerWrapper>
                            ) : (
                                <StyledSwitch
                                    checked={subscription?.isAutoRenew}
                                    onChange={toggleAutoRenew}
                                />
                            )}
                        </StyledRow>
                    </>     
                )}
                         
                <Divider />
                <StyledRow>
                    <Text.Body variant='bold'>Vehicle Connections</Text.Body>
                    <Text.Body variant='bold'>{vehicleConnection?.addOnQty}</Text.Body>
                </StyledRow>
                    {subscription?.showManageButton && (
                        <Button variant='secondary' onClick={onManagePress} style={{ margin: '36px auto' }}>Manage Payment Method & Billing History</Button>
                    )}

            </Offcanvas.Body>
        </StyledOffcanvas>
    )
}