import React, { useEffect } from 'react';
import styled from 'styled-components';
import styles from '../../utility/styles';
import Text from './Text';
import Button from './Button';

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: ${({ show }) => show ? 'block' : 'none'};
    background-color: rgba(0, 0, 0, 0.5);
`

const MessageWrapper = styled.div`
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px 16px 16px 24px;
    background-color: ${styles.colors.background};
    border-radius: 8px;
    overflow: hidden;
    min-width: 300px;
`

const Flag = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 8px;
    background-color: ${styles.colors.success};
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

const CloseButton = styled(Button)`
    background-color: ${styles.colors.white};
    color: ${styles.colors.background};
    padding: 4px 8px;
`

export default function MessageToast({ show, heading, body, onHide, options = {} }) {
    const { duration = null } = options

    return (
        <ModalWrapper {...{ show }}>
            <MessageWrapper>
                <Flag style={{ backgroundColor: options?.error ? styles.colors.error : styles.colors.success }} />
                <Text.Body variant='bold' color={styles.colors.success} style={{ marginBottom: '4px' }}>{heading}</Text.Body>
                <Text.Body style={{ marginBottom: '16px' }}>{body}</Text.Body>
                <ButtonWrapper>
                    <CloseButton onClick={onHide}>
                        Dismiss
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ marginLeft: '8px' }}>
                            <circle cx="8" cy="8" r="6" fill="#2C323B" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.664062 8.00008C0.664062 12.0502 3.94731 15.3334 7.9974 15.3334C12.0475 15.3334 15.3307 12.0502 15.3307 8.00008C15.3307 3.94999 12.0475 0.666748 7.9974 0.666748C3.94731 0.666748 0.664062 3.94999 0.664062 8.00008ZM9.41161 5.69044C9.67196 5.43009 10.0941 5.43009 10.3544 5.69044C10.6148 5.95079 10.6148 6.3729 10.3544 6.63325L8.9402 8.04746L10.3544 9.46167C10.6148 9.72202 10.6148 10.1441 10.3544 10.4045C10.0941 10.6648 9.67196 10.6648 9.41161 10.4045L7.9974 8.99027L6.58318 10.4045C6.32283 10.6648 5.90072 10.6648 5.64037 10.4045C5.38002 10.1441 5.38002 9.72202 5.64037 9.46167L7.05459 8.04746L5.64037 6.63325C5.38002 6.3729 5.38002 5.95079 5.64037 5.69044C5.90072 5.43009 6.32283 5.43009 6.58318 5.69044L7.9974 7.10465L9.41161 5.69044Z" fill="white" />
                        </svg>
                    </CloseButton>
                </ButtonWrapper>
            </MessageWrapper>
        </ModalWrapper>
    )
}