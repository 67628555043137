import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';
import Text from '../../shared/Text';
import Button from '../../shared/Button';
import FeatureTableRow from '../../shared/FeatureTableRow';
import styles from '../../../utility/styles';
import { ProIconGold } from '../../../utility/icons';


const StyledOffcanvas = styled(Offcanvas)`
    background-color: ${styles.colors.background};
    width: 50%;
    max-width: 600px;
    .offcanvas-header .btn-close {
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.27566 4.27566C3.90811 4.64322 3.90811 5.23914 4.27566 5.60669L10.1984 11.5294L4.27566 17.4521C3.90811 17.8197 3.90811 18.4156 4.27566 18.7832C4.64322 19.1507 5.23914 19.1507 5.60669 18.7832L11.5294 12.8604L17.5859 18.9169C17.9535 19.2845 18.5494 19.2845 18.9169 18.9169C19.2845 18.5494 19.2845 17.9535 18.9169 17.5859L12.8604 11.5294L18.7832 5.60669C19.1507 5.23914 19.1507 4.64322 18.7832 4.27566C18.4156 3.90811 17.8197 3.90811 17.4521 4.27566L11.5294 10.1984L5.60669 4.27566C5.23914 3.90811 4.64322 3.90811 4.27566 4.27566Z' fill='white'/%3e%3c/svg%3e") center / 1em auto no-repeat;
    }
    .offcanvas-header {
        background-color: ${styles.colors.background};
        color: ${styles.colors.white};
        border-bottom: 1px solid ${styles.colors.darkGray};
        font-family: var(--Font-Family, "Nunito Sans");
        font-size: var(--Para, 16px);
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 150% */
    }
    .offcanvas-body {
        padding: 32px 32px calc(80px + 32px) 32px;
        height: calc(100% - 56px);
        position: relative;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Title = styled(Offcanvas.Title)`
    font-family: var(--Font-Family, "Nunito Sans");
    font-size: var(--Para, 16px);
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 150% */
`;

const Header = styled(Offcanvas.Header)`
    padding: 16px 24px;
`;

const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledCol = styled.div`
    display: flex;
    flex-direction: column;
`;

const FooterSection = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    background-color: ${styles.colors.background};
    border-top: 1px solid ${styles.colors.darkGray};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    padding: 16px 32px;
`;

const LinkButton = styled(Button)`
    color: ${styles.colors.lightGray};
    font-size: 14px;
    font-weight: normal;
    text-decoration: underline;
    background-color: transparent;
`;

const FeatureFooter = styled.div`
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-self: stretch;
    border-radius: 8px;
    background: ${styles.colors.founders};
`;

const Divider = styled.hr`
    color: ${styles.colors.black};
`

const StyledButton = styled(Button)`
    background-color: ${({ color }) => color};
    color: ${({ textColor }) => textColor};
    border: 1px solid ${({ isOutline, textColor, color }) => isOutline ? textColor : color};
    flex: 1;
`

const returnStyling = index => {

    if (index % 2 === 0) {
        return {
            color: styles.colors.black,
            textColor: styles.colors.founders,
            isOutline: true
        }
    }
    return {
        color: styles.colors.founders,
        textColor: styles.colors.black,
        isOutline: false
    }
}

/**
 * 
 * @param {{
 *   show: boolean,
 *   onHide: () => void,
 *   onPress: (type: 'monthly' | 'annual') => void,
 *   subscription_options: {
 *     title: string,
 *     action: string,
 *   }[],
 *   pricing: {
 *     monthly: string,
 *     annual: string,
 *   },
 *   features: { text:string, freeText:string }[],
 *   keyFeatures: { text:string }[],
 *   disclaimer: string
 * }} props
 * @returns 
 */
export default function ProUpgradeMenu({ show, onHide, onPress, pricing, subscription_options, features = [], keyFeatures = [], disclaimer }) {
    return (
        <StyledOffcanvas show={show} onHide={onHide} placement="end" scroll={true}>
            <Header closeButton>
                <Title>Upgrade to TezLab Pro</Title>
            </Header>
            <Offcanvas.Body>
                <StyledRow style={{ justifyContent: 'center' }}>
                    <ProIconGold />
                </StyledRow>
                <Text.Heading color={styles.colors.founders} type='h3' style={{ textAlign: 'center', marginTop: '16px' }}>Pioneer The EV Lifestyle Like a Pro</Text.Heading>
                <Text.Body style={{ textAlign: 'center', margin: '16px' }}>Become a TezLab Pro member to unlock the full suite of features. Become a TezLab Pro member to unlock the full suite of features. Pro includes 1 vehicle connection and allows additional vehicles by add-on.</Text.Body>
                <FeatureTableRow.Heading />
                {features.map((feature, index) => (
                    <FeatureTableRow.Body
                        size='Body'
                        key={index}
                        freeText={feature.freeText}
                        text={feature.text}
                    />
                ))}
                <FeatureFooter>
                    <Text.Heading type='h2' variant='bold' color={styles.colors.black}>TezLab Pro</Text.Heading>
                    <Text.Body color={styles.colors.black} variant='bold' style={{ marginBottom: '16px' }}>{pricing.monthly} Monthly or {pricing.annual} Annually</Text.Body>
                    <Text.Body color={styles.colors.black} variant='bold' style={{ marginBottom: '16px' }}>Key Features</Text.Body>
                    {keyFeatures.map(({ text }, index) => (
                        <Text.Body color={styles.colors.black} key={text}>{text}</Text.Body>
                    ))}
                    <Divider />
                    {!!disclaimer && <Text.Caption color={styles.colors.black}>{disclaimer}</Text.Caption>}
                </FeatureFooter>
            </Offcanvas.Body>
            <FooterSection>
                <StyledRow style={{ justifyContent: 'center' }}>
                    {subscription_options?.map((option, index) => (
                        <StyledButton key={`option-${index + 1}`} onClick={() => onPress(option.action)} style={{ marginRight: '16px' }} {...returnStyling(index)}>{option.button_text}</StyledButton>
                    ))}
                </StyledRow>
            </FooterSection>
        </StyledOffcanvas>
    );
}