import React, { CSSProperties, Fragment } from 'react'
import styled from 'styled-components'
import styles from '../../utility/styles'

const LoadingComponent = styled.div`
	background-color: ${styles.functions.opacityModifier(
		styles.colors.darkGray,
		30
	)};
	height: ${({ height }) => height};
	width: ${({ width }) => width};
	border-radius: 8px;
`

/**
 * 
 * @param {{
 *   isLoading:boolean,
 *   height?:string,
 *   width:string,
 *   children:React.ReactNode,
 *   style:CSSProperties
 * }} props
 * @returns 
 */
export default function Skeleton({
	isLoading,
	height = '48px',
	width,
	children,
	style,
}) {
	return (
		<Fragment>
			{isLoading ? (
				<LoadingComponent {...{ style, height, width }} />
			) : (
				<Fragment>{children}</Fragment>
			)}
		</Fragment>
	)
}
